import { ReactNode, createContext, useMemo } from 'react'

type AuthPageProviderProps = {
  children: ReactNode
  tagline: string
  backgroundImageSrc: string
  logoSrc: string
  logoBackLink: string
  hasMarketing?: boolean
  hasSocialSignIn?: boolean
  hasNameFields?: boolean
  accountType?: string
}

type AuthPageProviderContext = {
  tagline: string
  backgroundImageSrc: string
  logoSrc: string
  hasMarketing: boolean
  logoBackLink: string
  hasSocialSignIn?: boolean
  hasNameFields?: boolean
  accountType?: string
}

export const AuthPageContext = createContext<AuthPageProviderContext>({
  tagline: '',
  backgroundImageSrc: '',
  logoBackLink: '/',
  logoSrc: '',
  hasMarketing: false,
})

export const AuthPageProvider = ({
  children,
  tagline,
  backgroundImageSrc,
  logoSrc,
  logoBackLink,
  hasSocialSignIn,
  hasNameFields,
  accountType,
  hasMarketing,
}: AuthPageProviderProps) => {
  const value = useMemo(
    (): AuthPageProviderContext => ({
      tagline,
      backgroundImageSrc,
      logoSrc,
      hasSocialSignIn,
      hasNameFields,
      accountType,
      hasMarketing,
      logoBackLink,
    }),
    [
      tagline,
      backgroundImageSrc,
      logoSrc,
      hasSocialSignIn,
      hasNameFields,
      accountType,
      hasMarketing,
      logoBackLink,
    ],
  )

  return (
    <AuthPageContext.Provider value={value}>
      {children}
    </AuthPageContext.Provider>
  )
}
