import { ProjectType } from 'common/projectType'

const Project: ProjectType = (global.Project = {
  debug: false,
  api: 'http://localhost:4000/',
  UNLOCK_PURCHASES: false,
  cwp: 'https://legal-utopia-cwp-git-staging-legal-utopia.vercel.app/',
  lp: 'https://legal-utopia-frontend-git-staging-legal-utopia.vercel.app/',
  gaApi:
    'https://legal-utopia-frontend-git-staging-legal-utopia.vercel.app/api/',
  liveApi: 'https://staging.api.legalutopia.co.uk/api/v1/',
  proxyApi: 'https://staging.api.legalutopia.co.uk/api/v1/',
  linkDomainPrefix: 'https://legalutopiastaging.page.link',
  stripe: 'pk_test_FDPtusaaQazanMehraItyOz100isLaJZhO',
  productSuffix: '_staging',
  appsflyer: 'Ch9iWDJxM8pW8xkLacck8g',
  appId: '1552690478',
  ga: '',
  logs: {
    DATA: true,
    STORE: true,
    DISPATCHER: true,
    STORAGE: true,
    SERVER: true,
  },
  cognito: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_YQDEvot5l',
    userPoolWebClientId: '1vaiuiudoc53nh0q966oj950vb',
    oauth: {
      domain: 'legal-utopia-staging.auth.eu-west-2.amazoncognito.com',
      scope: ['profile', 'email', 'openid'],
      redirectSignIn: 'legalutopia://',
      redirectSignOut: 'legalutopia://',
      responseType: 'code',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        // AdvancedSecurityDataCollectionFlag: true
      },
    },
  },
  cognitoWeb: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_YQDEvot5l',
    userPoolWebClientId: '1vaiuiudoc53nh0q966oj950vb',
    oauth: {
      domain: 'legal-utopia-staging.auth.eu-west-2.amazoncognito.com',
      scope: ['profile', 'email', 'openid'],
      redirectSignIn:
        'https://legal-utopia-cwp-git-staging-legal-utopia.vercel.app',
      redirectSignOut:
        'https://legal-utopia-cwp-git-staging-legal-utopia.vercel.app',
      responseType: 'code',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        // AdvancedSecurityDataCollectionFlag: true
      },
    },
  },
  grecaptcher: '6Lc1mf4UAAAAAC6uZ0HaGJ3ufgwCXY4GFoNhMeBD',
  formly: 'boilerplate',
})
export default Project
// if (typeof ENV_NAME !== 'undefined' && typeof ENV_TYPE !== 'undefined') {
// }
